import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { Layout } from "components/Layout";
import { CharacterList } from "components/CharacterList";

export default function Characters({
    data,
}: PageProps<Queries.CharactersQuery>) {
    return (
        <Layout>
            <CharacterList characters={data.characters.edges} />
        </Layout>
    );
}

export const query = graphql`
    fragment CharacterSummary on MarkdownRemark {
        id
        frontmatter {
            title
            slug
            thumbnail {
                childImageSharp {
                    gatsbyImageData(
                        placeholder: BLURRED
                        quality: 90
                        sizes: "(max-width: 768px) 65vw, 271px"
                    )
                }
            }
        }
    }
    query Characters($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ...Translation
                }
            }
        }
        characters: allMarkdownRemark(
            filter: {
                frontmatter: { type: { eq: "character" } }
                fields: { language: { eq: $language } }
            }
            sort: { fields: frontmatter___order }
        ) {
            edges {
                node {
                    ...CharacterSummary
                }
            }
        }
    }
`;

export { Head } from "components/GCSRewriteURL";
